.mainContainer {
  width: 100%;
  height: 100%;
}

.mainContent {
  top: 0;
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7) !important;
}
.mainText {
  color: white;
  font-size: xx-large;
}
.mainForm {
  display: flex;
  background-color: #212121;
  padding-left: 0.5em;
  border-radius: 10px;
  margin-top: 3em;
  align-items: center;
  width: 80%;
}
.mainFormInput {
  flex: 1 1;
  height: 50px;
  background-color: #212121;
  color: #b8a147;
  border: none;
  padding: 0 1.5em;
  font-size: 14px;
  outline: none;
  width: 20%;
}
.mainFormInput::placeholder {
  color: #b8a147;
}
.mainFormDivider {
  width: 1.5px;
  height: 40px;
  background-color: white;
}
.mainFormDropDownDivider {
  width: 1.5px;
  height: 30px;
  background-color: white;
}
.mainButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b8a147;
  height: 53px;
  border: none;
  border-radius: 10px;
  width: 60px;
  text-align: center;
  padding: 10px;
}
.mainFormDropDown {
  min-width: 7rem;
  position: relative;
  margin: 0 1rem;
  padding: 3px;
}
.mainFormDropDown * {
  box-sizing: border-box;
}
.select {
  border-radius: 5px;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  transition: background 0.3s;
}
.select-clicked {
  /* border: 0.5px #B8A147 solid; */
  box-shadow: 0 0 0.2rem #b8a147;
}
.caret {
  color: #b8a147;
  transition: 0.3s;
}
.caret-rotate {
  transform: rotate(180deg);
}
.menu {
  list-style: none;
  padding: 0.2rem 0.5rem;
  background-color: #212121;
  box-shadow: 0 0.2rem 0.5rem #b8a147;
  border-radius: 0.5rem;
  color: #b8a147;
  position: absolute;
  top: 3.5rem;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  opacity: 0;
  display: none;
  transition: 0.2s;
  z-index: 1;
}
.menu li {
  padding: 0.7rem 0.5rem;
  margin: 0.3rem 0;
  border-radius: 0.5rem;
  cursor: pointer;
}
.menu li:hover {
  background: #b8a147;
  color: white;
}
.active_vacancies {
  background: #b8a147;
  color: white;
}
.menu-open {
  display: block;
  opacity: 1;
}
.dropdown {
}
/* CARD */

.Card {
  color: white;
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  text-align: left;
  background: linear-gradient(180deg, rgba(213, 187, 84, 0.1) 0%, rgba(23, 23, 23, 0) 100%);
  border-radius: 10px;
  padding: 2rem;
  margin-bottom: 30px;
}
.CardHeading {
  font-size: x-large;
  font-weight: 700;
  padding-bottom: 20px;
}
.CardDetail {
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  color: #b8a147;
  font-weight: 500;
  gap: 1rem;
}
.CardSection {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}
.CardButton_1 {
  font-size: 12px;
  margin: 0px 5px;
  padding: 6px 10px;
  border: 0.5px solid white;
  height: 42px;
  border-radius: 5px;
  cursor: pointer;
}

.CardButton_2 {
  width: 120px;
  font-size: 12px;
  height: 42px;
  color: white;
  border-radius: 5px;
  margin: 0px 5px;
  padding: 6px 10px;
  background: linear-gradient(180deg, #d5bb54 0%, #837230 100%);
  font-weight: bold;
  border: none;
  cursor: pointer;
}
.ButtonGroup {
  display: flex;
}
.CardIcons {
  font-size: larger;
  margin: 0px 5px;
}
.Card:hover {
  background: linear-gradient(180deg, #d5bb54 0%, #837230 100%);
}
.Card:hover .CardButton_2 {
  background: #212121;
  border: 1px solid #fff;
  color: #b8a147;
}
.Card:hover .CardDetail {
  color: white;
}
/*.Card:hover .CardButton_1{
    background: rgb(238,122,39);
    border: 1px solid #fff;
    color :white
}
.Card:hover{
    background: rgb(238,122,39);
    color: white;
} */
/* Section_1 */
.mainSection {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 50px;
  /* margin-top: 20px; */
}
.sectionFilter {
  width: 35%;
}
.sectionCard {
  width: 60%;
}
.sectionInnerFilter {
  background: linear-gradient(180deg, #d5bb54 0%, #837230 100%);
  margin: auto;
  width: 70%;
  min-height: 90vh;
  height: auto;
  border-radius: 10px;
}
.filterHeader {
  display: flex;
  width: auto;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 30px 20px;
}
.filterButton {
  /* width: 90px; */
  font-weight: 500;
  background: #212121;
  color: #b8a147;
  padding: 6px 13px;
  border: none;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
}
.filterDistance {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px 30px;
}
.filter__date__posted {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 30px;
}
.distance_text {
  margin: 0px;
  text-align: left;
  font-size: 20px;
}
.filterDistance input {
  border-radius: 5px;
  border: none;
  background: #212121;
  padding: 11px 10px;
  color: #b8a147;
  /* outline: 2px ridge rgb(242,158,99); */
}
.filterDistance input:focus {
  outline: none;
  color: #b8a147;
}
.filterDistance input::placeholder {
  color: #b8a147;
  font-size: 13px;
  padding-left: 3px;
}
.rangeBox {
  font-size: larger;
  color: white;
  padding: 10px 0px;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.range {
  border-radius: 5px;
  background: white;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  width: 60%;
  accent-color: white;
}
.filterContext {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 0 25px;
  text-align: left;
}
.filterContext h4 {
  margin: 7px;
  font-size: larger;
  color: white;
  font-weight: 700;
}
.filterContext p {
  margin: 6px;
  font-size: large;
  color: white;
  font-size: 15px;
}
.section_1_input {
  color: #b8a147;
  border: none;
}
.section_1_input:focus {
  outline: none;
}
.section_1_input::placeholder {
  color: #b8a147;
}
#menu_2 {
  border: 2px solid red;
}
/* Apply */
.apply {
  height: fit-content;
  display: flex;
  align-items: center;
  /* padding-bottom: 8em; */
  flex-direction: column;
  position: relative;
  max-width: 100vw;
}
.mainApply {
  width: 100vw;
  background-color: #b8a147;
  height: 100%;
  position: relative;
  margin: 40px 0;
  align-content: center;
}
.applyContent {
  margin-left: 40px;
  margin: auto;
  padding: 70px 70px;
  color: #fff;
  width: 65%;
  height: 100%;
  position: relative;
  top: 0;
  display: flex;
  gap: 20px;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  /* margin-left: 325px; */
  text-align: left;
}
@media only screen and (max-width: 992px) {
  .applyContent {
    padding: 30px 10px;
    font-size: 15px;
    width: 90%;
  }
}
@media only screen and (max-width: 750px) {
  .applyContent {
    padding: 30px 10px;
    font-size: 12px;
    width: 90%;
  }
}
.classButton {
  height: 42px;
  color: #b8a147;
  border-radius: 5px;
  padding: 6px 22px;
  background: #171717;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.classButton:hover {
  transform: translateY(-5px);
}
.heading {
  font-size: x-large;
  font-weight: 600;
}
.sort-by {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 15px;
}

.sort-menu {
  font-size: 15px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
.section_1_input {
  margin-left: 10px;
  padding: 7px 5px;
  border: 1.5px solid rgb(204, 204, 204);
  border-radius: 5px;
}
.section_1_input:focus {
  outline: none;
}
.section_input_li {
  margin-left: 60px;
  border-radius: 5px;
  font-size: 17px;
  padding: 1px 20px;
  list-style: none;
  text-align: center;
  background: #b8a147;
  position: absolute;
}
.section_input_li li {
  cursor: pointer;
  padding: 1px 10px;
  margin: 5px 0px;
}
.section_input_li li:hover {
  background-color: white;
  color: #b8a147;
}

@media only screen and (max-width: 750px) {
  .select {
    gap: 5px;
  }
  .mainText {
    padding: 0px 5px;
    font-size: 20px;
  }
  .mainForm {
    width: 80%;
  }
  .mainFormInput {
    font-size: 10px;
    height: auto;
  }
  .mainButton {
    height: 43px;
    width: 40px;
  }
  .mainFormDivider {
    height: 20px;
  }
  .mainFormDropDownDivider {
    height: 20px;
  }
  .mainFormDropDown {
    font-size: 10px;
    min-width: 5%;
    margin: 0 0rem;
  }

  /* CARD */

  .Card {
    margin: 0 0px;
    padding: 20px 20px;
    margin-bottom: 15px;
  }
  .CardHeading {
    font-size: 15px;
    font-weight: 700;
    padding-bottom: 20px;
  }
  .CardDetail {
    font-size: 10px;
    font-weight: 500;
    gap: 5px;
  }
  .CardButton_1 {
    font-size: 9px;
    padding: 0px 4px;
    height: auto;
  }
  .CardSection {
    flex-direction: column;
  }

  .CardButton_2 {
    height: auto;
    font-size: 9px;
    padding: 8px 4px;
  }
  .CardIcons {
    font-size: 10px;
    margin: 0px 5px;
  }
  .sort-menu {
    width: auto;
    padding: 0px 0px;
  }
  .sort-menu {
    display: flex;
    align-items: center;
    font-size: 10px;
    padding: 5px;
  }
  .section_1_input {
    width: 100px;
    margin-left: 10px;
    padding: 3px 5px;
  }
  .results {
    font-size: 15px;
  }
  .section_input_li {
    margin-top: 130px;
    margin-left: 10px;
    border-radius: 5px;
    font-size: 12px;
    padding: 1px 20px;
    position: absolute;
  }
  .section_input_li li {
    padding: 1px 10px;
    margin: 5px 0px;
  }
  /* ////////////////////Section_1///////////////////////////// */
  .mainSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
  .sectionFilter {
    width: 100%;
  }
  .sectionCard {
    width: 100%;
    padding: 0px 0px;
  }
  .sectionInnerFilter {
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    width: 95%;
    min-height: 60vh;
    height: auto;
  }
  .filter_header_distance {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
  }
  .filterHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 10px;
  }
  .filterButton {
    width: 75px;
    font-size: 10px;
    padding: 10px 0px;
    height: auto;
    border-radius: 10px;
  }
  .distance_text {
    margin: 0px;
    text-align: center;
    font-size: 15px;
  }
  .filterHeader h1 {
    font-size: 15px;
  }
  .filterDistance {
    font-size: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 30px;
  }
  .filterDistance input {
    border-radius: 5px;
    border: none;
    padding: 7px 6px;
    font-size: 10px;
  }
  .filterDistance input::placeholder {
    font-size: 10px;
    padding-left: 3px;
  }
  .rangeBox {
    font-size: 15px;
    padding: 5px 0px;
    justify-content: center;
    display: flex;
    flex-direction: row;
  }

  .range {
    border-radius: 5px;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
    width: 50%;
  }
  .filterContext {
    padding: 5px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
  }
  .filterContext h4 {
    margin: 7px;
    font-size: 12px;
  }
  .filterContext p {
    margin: 3px;
    font-size: 10px;
  }
  .Section_1_Input {
    border: none;
    font-size: 15px;
  }
  .heading {
    font-size: 20px;
  }
}
/* 
  @media only screen and (max-width:750px){
    .sectionInnerFilter{
      width: 90%;
    }
    .sectionCard{
        width: 100%;
    }
    .filter_text{
        font-size: 20px;
    }
  } */
@media only screen and (max-width: 1150px) {
  .sectionInnerFilter {
    width: 90%;
  }
  .sectionCard {
    width: 90%;
  }
  .filter_text {
    font-size: 20px;
  }
}

