.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 20px;
  position: relative;
  color: #fff;
}

.title {
  font-size: 28px;
  font-weight: 600;
  letter-spacing: -1px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 30px;
  color: #B8A147;
}

.title::before {
  width: 18px;
  height: 18px;
}

.title::after {
  width: 18px;
  height: 18px;
  animation: pulse 1s linear infinite;
}

.title::before,
.title::after {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  border-radius: 50%;
  left: 0px;
  background-color: #B8A147;
}

.message, 
.signin {
  font-size: 14.5px;
  color: rgba(255, 255, 255, 0.7);
}

.signin {
  text-align: center;
}

.signin a:hover {
  text-decoration: underline #B8A147;
}

.flex1 {
  display: flex;
  width: 100%;
  gap: 10px;
}

.form label {
  position: relative;
}

.form label .input1 {
  background-color: #333;
  color: #fff;
  width: 100%;
  padding: 20px 05px 05px 10px;
  outline: 0;
  border: 1px solid rgba(105, 105, 105, 0.397);
  border-radius: 5px;
}

.form label .input1:hover {
  border: 1px solid #B8A147;
}

.form label .input1:focus {
  border: 1px solid #B8A147;
}

.form label .input1 + span {
  color: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 10px;
  top: 12.5px;
  font-size: 0.9em;
  cursor: text;
  transition: 0.3s ease;
}

.form label .input1:focus + span,
.form label .input1:not(:placeholder-shown) + span {
  color: #B8A147;
  top: 0px;
  font-size: 0.7em;
  font-weight: 600;
}

.input1 {
  font-size: medium;
}

.submit {
  width: 25%;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  transform: .3s ease;
  background-color: #B8A147;
}

.submit:hover {
  background-color: #B8A147;
}

@keyframes pulse {
  from {
    transform: scale(0.9);
    opacity: 1;
  }

  to {
    transform: scale(1.8);
    opacity: 0;
  }
}
