@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 25px;
  height: 25px;
  border: 3px solid #3b99f0;
  border-top: 3px solid #f3f3f3;
  border-radius: 50%;
  animation: spinner 0.9s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
}

.model__container__bigger {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2em;
  background-color: rgba(0, 0, 0, 0.5);
  animation: model__container__bigger 0.3s ease-in-out;
}
@keyframes model__container__bigger {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.loading-spinner-bigger {
  width: 50px;
  height: 50px;
  border: 3px solid #3b99f0;
  border-top: 3px solid #f3f3f3;
  border-radius: 50%;
  animation: spinner 0.9s linear infinite;
}
