.container {
    /* width: 30%; */

    /* min-width: 250px; */
    height: 360px;
    /* flex-wrap: wrap; */

}

.card {
    width: 100%;
    height: 100%;
    /* background-image: url('../../assets/Worker.jpg'); */
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
}

.overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 15%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    justify-content: start;
    align-items: center;
    transition: height 0.3s ease;
}

.card:hover .overlay {
    height: 80%;
}

.text {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    padding: 10px;
    text-align: center;
}