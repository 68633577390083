/* In your CSS or Tailwind file */
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;700&display=swap');

body,
html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  /* background-color: #000; */
  /* font-family: "Plus Jakarta Sans"; */
  /* overflow-x: hidden; */
  /* overflow: hidden; */
  /* max-width: 100vw; */
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.App {
  text-align: center;
}

/* Navbar Style  */


.theme-bg_1 {
  background: linear-gradient(120deg, rgba(237, 213, 105, 0.90) 0%, rgba(151, 118, 25, 0.90) 100%);
}

/* Pacific Rim Style  */

.NavBar_Main_Container {
  display: flex;
  justify-content: space-between;
  padding-right: 2%;
  align-items: center;
  position: fixed;
  z-index: 995;
  width: 100%;
  height: 80px;
  /* background-color: black; */
  z-index: 999999999;

}

.navbarInnerContainer {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1025px) {
  .navbarInnerContainer {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}



.NavLink_App_Smaller_Devices {
  margin-right: 0.5%;
}




.NavbarButtonContainer {

  width: 165px;
  height: 42px;
  flex-shrink: 0;
  color: #EDD569;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #EDD569;
  padding-bottom: 3px;
  transition: all ease-in 300ms;
  box-shadow: inset 0 0 0 0 #EDD569;
  transition: color 0.6s ease-in-out, box-shadow 0.6s ease-in-out;
  cursor: pointer;
}



.NavbarButtonContainer:hover {
  box-shadow: inset 200px 0 0 0 #CEB551;
  color: white;

}

.navbarConTactButtonIcon {
  margin-right: 5px;
  margin-top: 2px;
}

.headphoneLogo {
  height: 20px;
}

.Active_Header {
  transition: 400ms ease-in-out;
  /* background-color: rgba(255, 255, 255); */


  /* background-color: red; */

}

.Logo_Text_style {
  font-size: 1.5rem;
  color: black;
  font-family: var(--SubHeading-font-family);
}

.Logo_Saloon_Text_Style {
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: var(--SubHeading-font-family);
}

.Join_Button_Style {
  padding: 12px;
  padding-left: 48px;
  padding-right: 48px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #353535;
  color: white;
  margin-right: 10px;
  font-family: var(--SubHeading-font-family);
  cursor: pointer;
}

.Join_Button_Style_Small_Device {
  padding: 10px;
  padding-left: 40px;
  padding-right: 40px;
  outline: none;
  border: none;
  border-radius: 5px;
  /* background-color: #353535; */
  color: #EDD569;
  margin-right: 0px;
  margin-top: 5px;
  font-family: var(--SubHeading-font-family);
  cursor: pointer;
}

.Navlink_Main_Conatiner {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;

}

.NavbarButtonContainer.small_screen {
  display: none;
}

.Navlink_Wrapper {
  display: flex;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: space-between;
}

.Navlink_Wrapper a {
  width: auto;
}


.Navlink_Wrapper_Second {
  display: flex;
  /* width: 100%; */
  align-items: center;
  justify-content: space-between;
}

.Navlink_Wrapper_Second a {
  width: auto;
}

.Button_Main_Container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.Button_Drop_Container {
  display: flex;
  align-items: center;
  background-color: #6fa66b;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
}

.Button_Drop_Container_Small_Device {
  display: flex;
  align-items: center;
  background-color: #6fa66b;
  border-radius: 5px;
  padding-left: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: center;
}

.Select_Option_Style {
  font-size: 1.5rem !important;
  color: black;
  font-family: var(--SubHeading-font-family);
}

.Select_Option_Button {
  padding: 1px;
  font-size: 1.5rem;
  /* width: 10rem; */
  font-family: var(--SubHeading-font-family);
}

.Select_Option_Button_Small_Device {
  font-size: 1.5rem;
  /* padding-right: 5px; */
  font-family: var(--SubHeading-font-family);
}

.Select_Option_Style_Small_Device {
  font-size: 1.5rem;
  margin-top: 5px;
}

.Link_Style {
  font-size: 16px;
  text-decoration: none;
  color: black;
  font-family: var(--Heading-font-family);
  font-weight: 300;
  margin-right: 1%;
  cursor: pointer;

}

.Link_Style_Scroll {
  font-size: 16px;
  color: #EDD569;
  font-family: var(--Heading-font-family);
  font-weight: 300;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  text-underline-offset: 5px;
  text-decoration-thickness: 2px;
  margin-right: 1%;


}

.navbarLogoContainer {}

.Link_Style:hover {
  /* background-color: rgba(0, 0, 0, 0.5); */
  font-family: var(--Heading-font-family);
  font-weight: 800;
  color: #EDD569;
}


.Link_Style_Scroll:hover {
  /* background-color: rgba(0, 0, 0, 0.5); */
  font-family: var(--Heading-font-family);
  font-weight: 600;
}

.newMenu:hover {
  /* background-color: rgba(0, 0, 0, 0.5); */
  font-family: var(--Heading-font-family);
  width: 60%;
  font-weight: 600;
  color: white;
}

.Link_is_Active {
  color: black;
}

.Link_Style_Small_Device {
  font-size: 1.5rem;
  text-decoration: none;
  color: black;
  padding-top: 15px;
  font-family: var(--SubHeading-font-family);
  font-weight: 600;
  padding: 10px;
  border-radius: 25px;
  min-width: 65%;
  text-align: center;
}

.Navbar_Image_Style {
  height: 22px;
  width: 22px;
  border-radius: 100px;
}

.Select_Container {
  display: flex;
  align-items: center;
}

.NavLink_App_Smaller_Devices {
  display: none;
}

.NavLink_App_Smaller_Overlay {
  position: absolute;
  top: 92px;
  left: 0;
  right: 0;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  background-color: #171717;
  transition: 0s ease;
  flex-direction: column;
  overflow: hidden;
  z-index: 5;
  border-radius: 5px;
}

.Navlink_App_Links_Wrapper_SmallScreen_links {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 15px;
  padding-top: 5%;
  margin-bottom: 5%;
}

.Nav_Image_Style {
  height: auto;
  width: 80%;
}


.logoContainer {
  display: flex;
  align-items: center;
}

.newMenu {
  font-size: 1.5rem;
  color: black;
  font-weight: 500;
  margin-left: 4rem;
  font-family: var(--Heading-font-family);
  cursor: pointer;
}


/* swiper css start  */

.wrapper {
  height: 200px;
  margin-top: 5%;
  margin-bottom: 3%;

}

.wrapper h3 {
  color: #1a4048;
  display: flex;
  justify-content: center;
  /* margin: 7% 0 3% 0%; */
}

.div {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.zablelogo {
  margin-top: 13px;
}

.swiper {
  /* background-color: #f7f7f7; */
  padding: 20px 20px 10px 20px;
}

.slide {
  z-index: 2;
}

.sliderbutton {
  font-size: 1.3rem;
  width: 40px;
  height: 40px;
  color: #05c8e8;
  border-radius: 50%;
  background-color: black;
  cursor: pointer;
  border: 1px solid #8b8b8b;
  cursor: pointer;
  margin-top: -4.2%;
  margin-bottom: 2%;
}

.button {
  display: flex;
  row-gap: 2rem;
  justify-content: space-between;
  align-items: center;
  z-index: 500;
  position: relative;
}

@media (max-width: 420px) {
  .swiper {
    width: 370px;
  }

  .sliderbutton {
    margin-top: -19%;
  }

  .sliderbutton {
    font-size: 1.3rem;
    width: 50px;
    height: 50px;
    color: #05c8e8;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
    border: 1px solid #8b8b8b;
    cursor: pointer;
    margin-bottom: 2%;
  }
}

@media screen and (min-width: 421px) and (max-width: 600px) {
  .sliderbutton {
    margin-top: -18%;
  }
}

@media screen and (min-width: 601px) and (max-width: 770px) {
  .sliderbutton {
    margin-top: -9%;
  }

  .zablelogo {
    margin-top: 13px;
  }
}

@media screen and (min-width: 771px) and (max-width: 900px) {
  .sliderbutton {
    margin-top: -9%;
  }

  .zablelogo {
    margin-top: 15px;
  }
}

@media screen and (min-width: 901px) and (max-width: 1200px) {
  .sliderbutton {
    margin-top: -7%;
  }

  .zablelogo {
    margin-top: 15px;
  }
}


/* swiper css end  */

/* src/components/ImageSlider.css */
/* src/components/ImageSlider.css */
.slide {
  height: 300px;
  /* Adjust the height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
}

.slick-prev:before,
.slick-next:before {
  color: white;
  /* Arrow color */
}

/* Logo slider start  */



/* LogoSlider.css */
/* LogoSlider.css */
.logo-slider-wrapper {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.logo-slider-wrapper h2 {
  margin-bottom: 20px;
}

.mySwiper {
  padding: 20px 0;
}

.logo-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-image {
  max-width: 100px;
  height: auto;
}

.swiper-button-prev,
.swiper-button-next {
  color: #000;
}

.swiper-pagination-bullet {
  background: #000;
}



/* Logo Slider End   */



/* start  */
.advisoryboard_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: 50px;
}



.mySwiper {
  background-color: white;
  width: 100%;
  position: relative;
  /* --swiper-pagination-color: black; */
  display: flex;
  flex-direction: column;
}


.advisory-swiper-card {
  width: 20% !important;
  height: 150px !important;
}

.advisoryboard_infocard {
  /* background: white;
  box-shadow: 3px 1px 44px -12px rgba(0, 0, 0, 0.16); */
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90%;
  width: 85%;
  cursor: pointer;
  padding: 2.8%;
}

.advisoryboard_infocard_intro_points_container {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  overflow-y: scroll;
  scroll-behavior: smooth;
}

.advisoryboard_infocard_intro_points_container::-webkit-scrollbar {
  width: 0px;
}



.slider_img {
  z-index: 10000;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.swiper-button-next,
.swiper-button-prev {
  color: #000;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 20px;
}

.custom-swiper-button {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
}

.swiper-button-next.custom-swiper-button {
  right: -50px;
}

.swiper-button-prev.custom-swiper-button {
  left: -50px;
}


/* end   */






@media (max-width: 321px) {
  .NavLink_App_Smaller_Devices {
    display: flex;
    margin-left: -70px;
    margin-bottom: 3%;
  }

  .navbarConTactButton {
    display: none;
  }

  .NavBar_Main_Container {}

  .NavbarButtonContainer {
    display: none;
  }

  .NavbarButtonContainer.small_screen {
    margin-right: 85px;
    display: flex;
    align-items: center;
  }

  .collapse_button_number {
    display: flex;
    align-items: center;
    margin-right: 13px;
  }

  .Navlink_Wrapper {
    display: none;
  }

  .Nav_Image_Style {
    height: auto;
    width: 100px;
  }


  .headphoneLogo {
    width: 10px;
  }

  .Link_Style {
    margin-bottom: 2%;
    font-weight: 400;
    font-size: 14px;
  }

  .Link_Style_Scroll {
    margin-bottom: 2%;
    font-weight: 400;
    font-size: 14px;
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;

  }

  .NavLink_App_Smaller_Overlay {
    top: 92px;
  }

}

@media (min-width: 321px) and (max-width: 480px) {

  .NavLink_App_Smaller_Devices {
    display: flex;
    margin-bottom: 4%;
    margin-right: 27%;
    /* margin-left: 20; */
  }

  .navbarConTactButton {
    display: none;
  }


  .NavBar_Main_Container {
    /* display: flex;
    justify-content: space-between;
    padding-left: 0%;
    padding-right: 0%;
    align-items: center;
    padding-top: 2.4rem;
    padding-bottom: 1.4rem;
    position: fixed;
    z-index: 995;
    width: 100%;
    height: 70px; */

  }

  .Navlink_Wrapper {
    display: none;
  }

  .NavbarButtonContainer {
    display: none;
  }

  .NavbarButtonContainer.small_screen {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }

  .collapse_button_number {
    display: flex;
    align-items: center;
  }

  .Link_Style {
    margin-bottom: 2%;
    font-weight: 400;
    font-size: 14px;
  }

  .Link_Style_Scroll {
    margin-bottom: 2%;
    font-weight: 400;
    font-size: 14px;
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;

  }

  .Nav_Image_Style {
    height: auto;
    width: 100px;
  }

  .NavbarButtonContainer {
    display: none;
  }

  .NavbarButtonContainer.small_screen {
    display: flex;
    align-items: center;
  }

  .collapse_button_number {
    display: flex;
    align-items: center;
    margin-right: 13px;
  }

  .headphoneLogo {
    width: 10px;
  }

  .NavLink_App_Smaller_Overlay {
    top: 92px;
  }


}

@media (min-width: 481px) and (max-width: 576px) {
  .NavLink_App_Smaller_Devices {
    display: flex;
    margin-bottom: 4%;
    margin-right: 5%;
  }

  .NavBar_Main_Container {
    /* display: flex;
    justify-content: space-between;
    padding-left: 0%;
    padding-right: 0%;
    align-items: center;
    padding-top: 2.4rem;
    padding-bottom: 1.4rem;
    position: fixed;
    z-index: 995;
    width: 100%;
    height: 70px; */

  }

  .Navlink_Wrapper {
    display: none;
  }

  .NavbarButtonContainer {
    display: none;
  }

  .NavbarButtonContainer.small_screen {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }

  .collapse_button_number {
    display: flex;
    align-items: center;
  }

  .Link_Style {
    margin-bottom: 2%;
    font-weight: 400;
    font-size: 14px;
  }

  .Link_Style_Scroll {
    margin-bottom: 2%;
    font-weight: 400;
    font-size: 14px;
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;

  }

  .Nav_Image_Style {
    height: auto;
    width: 100px;
  }


  .navbarConTactButton {
    width: 211px;
    height: 35px;
    font-size: 13px;
    margin-right: 25px;
  }

  .headphoneLogo {
    width: 10px;
  }

  .NavLink_App_Smaller_Overlay {
    top: 92px;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .NavLink_App_Smaller_Devices {
    display: flex;
    margin-bottom: 4%;
    margin-right: 0%;
  }

  .NavBar_Main_Container {
    /* display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2.4rem;
    padding-bottom: 1.4rem;
    position: fixed;
    z-index: 995;
    width: 100%;
    height: 70px; */
  }

  .Navlink_Wrapper {
    display: none;
  }

  .NavbarButtonContainer {
    display: none;
  }

  .NavbarButtonContainer.small_screen {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }

  .collapse_button_number {
    display: flex;
    align-items: center;
  }

  .Link_Style {
    margin-bottom: 2%;
    font-weight: 400;
    font-size: 14px;
  }

  .Link_Style_Scroll {
    margin-bottom: 2%;
    font-weight: 400;
    font-size: 14px;
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;

  }

  .Nav_Image_Style {
    height: auto;
    width: 100px;
  }

  .navbarConTactButton {
    width: 210px;
    height: 35px;
    font-size: 13px;
    margin-right: 5px;
  }

  .headphoneLogo {
    width: 10px;
  }

  .NavLink_App_Smaller_Overlay {
    top: 92px;
  }
}





@media (min-width: 769px) and (max-width: 992px) {

  .NavLink_App_Smaller_Devices {
    display: flex;
  }

  /* .NavBar_Main_Container {
    display: flex;
    justify-content: space-between;
    padding-right: 3%;
    align-items: center;
    padding-top: 2.4rem;
    padding-bottom: 1.4rem;
    position: fixed;
    z-index: 995;
    width: 100%;
    height: 100px;

  } */

  .NavbarButtonContainer {
    display: none;
  }

  .NavbarButtonContainer.small_screen {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }

  .collapse_button_number {
    display: flex;
    align-items: center;
  }

  .Navlink_Wrapper {
    display: none;
  }

  .Nav_Image_Style {
    /* height: auto;
    width: 200px; */
  }

  .navbarConTactButton {
    /* width: 244px;
    height: 45px;
    font-size: 15px;
    margin-top: 7%; */
  }

  .headphoneLogo {
    /* height: 15px; */
  }

  .Link_Style {
    /* margin-bottom: 1%; */
  }

  .Link_Style_Scroll {
    /* margin-bottom: 1%; */

  }
}

@media (min-width: 992px) and (max-width: 1024px) {

  .NavLink_App_Smaller_Devices {
    display: flex;
  }

  .NavbarButtonContainer {
    display: none;
  }

  .NavbarButtonContainer.small_screen {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }

  .collapse_button_number {
    display: flex;
    align-items: center;
  }

  .NavBar_Main_Container {
    /* display: flex;
    justify-content: space-between;
    padding-right: 3%;
    align-items: center;
    padding-top: 2.4rem;
    padding-bottom: 1.4rem;
    position: fixed;
    z-index: 995;
    width: 100%;
    height: 100px; */

  }

  .Navlink_Wrapper {
    display: none;
  }

  .Nav_Image_Style {
    height: auto;
    width: 200px;
  }

  .navbarConTactButton {
    width: 244px;
    height: 45px;
    font-size: 15px;
    margin-left: -43%;
    margin-top: 5%;
  }

  .headphoneLogo {
    height: 15px;
  }

  .NavbarButtonContainer {
    /* width: 100px;
    margin-left: 200px; */
  }

  .Link_Style {
    margin-bottom: 1%;
  }

  .Link_Style_Scroll {
    margin-bottom: 1%;

  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .navbarConTactButton {
    width: 244px;
    height: 45px;
    font-size: 15px;
    margin-left: -43%;
  }

}

@media (min-width: 1281px) {
  .NavBar_Main_Container {
    /* height: 90px; */
  }

  .Navlink_Wrapper {}

  .Nav_Image_Style {
    margin-right: 10%;
    height: auto;
    width: 125px;
  }

  .navbarConTactButton {
    width: 244px;
    height: 45px;
    font-size: 15px;
  }


  .Link_Style {
    font-weight: 300;
    font-size: 13px;
  }

  .Link_Style_Scroll {
    font-weight: 300;
    font-size: 13px;
  }

}

@media (min-width: 1200px) {

  .NavBar_Main_Container {
    /* height: 90px; */
  }

  .Navlink_Wrapper {}

  .Nav_Image_Style {
    margin-right: 10%;
    height: auto;
    width: 125px;
  }

  .navbarConTactButton {
    width: 244px;
    height: 45px;
    font-size: 15px;
    margin-left: 17%;
    margin-top: 15px;
  }

  .Link_Style {
    font-weight: 400;
    font-size: 16px;
  }

  .Link_Style_Scroll {
    font-weight: 400;
    font-size: 16px;
  }

  .NavBar_Main_Container {
    /* justify-content: space-around; */
  }
}

@media (min-width: 1384px) and (max-width: 1440px) {}

@media (min-width: 1440px) and (max-width: 1650px) {}

@media (min-width: 1650px) {}




/* Pacific Rim Style End */

.responsive-nav {
  position: absolute;
  /* top: 20%; */
  left: 16%;
  width: 50%;
  margin: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
  background-color: #06060679;
  border-radius: 15px;

}

.navbarBg {
  background-color: black;
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbarContainer {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.navbarLinksContainer {
  width: 45%;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.Link_Style {
  color: white;
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;
  transition: transform 0.5s ease;
}

.Link_Style:hover {
  color: whitesmoke;
  line-height: 30px;
  /* border-bottom: 1px solid #a2a1a17c; */
  transform: translateY(-3px);
}


/* Header Style  */

.headerMain {
  display: flex;
  width: 100%;
  align-items: center;
  height: 600px;
  padding-top: 80px;
}

.headerMainContainer1 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #edd569e7;
  width: 50%;
  height: 600px;
  background-image: url("./assets/headerLeftBgImg.svg");
  background-size: cover;
  background-position: center;

}

.headerMainChild1 {
  position: relative;
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.headerMainChild1Head {
  color: white;
  font-size: 40px;
  text-align: left;
  margin-bottom: 0;
}

.headerMainChild1Para {
  color: white;
  font-size: 13px;
  text-align: left;
}

.headerMainChild1Button {
  margin-top: 30px;
  width: 170px;
  height: 45px;
  flex-shrink: 0;
  color: black;
  background-color: white;
  border: none;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  transition: all ease-in 300ms;
}

.headerMainChild1Button:hover {
  transform: scale(1.04);
  box-shadow: 3px 1px 44px -12px #00000094;
}

.headerMainChild2 {
  position: relative;
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.headerMainChild2Head {
  color: white;
  font-size: 40px;
  text-align: left;
  margin-bottom: 0;
}

.headerMainChild2Para {
  color: white;
  font-size: 13px;
  text-align: left;
}

.headerMainChild2Button {
  margin-top: 30px;
  width: 170px;
  height: 45px;
  flex-shrink: 0;
  color: black;
  background-color: white;
  border: none;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  transition: all ease-in 300ms;
}

.headerMainChild2Button:hover {
  transform: scale(1.04);
  box-shadow: 3px 1px 44px -12px #ffffff94;
}

.headerMainContainer2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.77);
  width: 50%;
  height: 520px;
  background-image: url("./assets/headerRightBgImg.svg");
  background-size: cover;
  background-position: center;
  z-index: 101;
}

.jobSeekerImg {
  position: absolute;
  top: -15%;
  left: -14%;
}

.companiesImg {
  position: absolute;
  top: -8%;
  right: -2%;
}

/* Outsourcing style  */
.outsourcingMain {}

.firstSection {
  /* background-image: url("./assets/firstSectionBgImgFull.svg"); */
  /* background-color: rgba(0, 0, 0, 0.803); */
  /* background-size: cover;
  background-position: center; */

  background-color: #171717;
  padding-bottom: 70px;
  position: relative;
}

.firstSectionContainer {
  width: 90%;
  margin-left: auto;
  /* margin-right: auto; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-top: 80px; */

}

.firstSectionChild1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.starImageContainer {
  position: absolute;
  bottom: 0;
  left: -7%;
  width: 30%;

}

.detail_gradient {
  /* background-image: linear-gradient(180deg, red,yellow); */
  background-image: linear-gradient(180deg, rgba(23, 23, 23)10%, rgba(255, 255, 255, 0)100%);
}

.firstSectionChild2 {
  width: 70%;
}

.firstSectionChild1Head {
  color: white;
  font-size: 40px;
  text-align: left;
  margin-bottom: 0;
}

.firstSectionChild1Para {
  color: white;
  font-size: 13px;
  text-align: left;
}

.firstSectionChild1Button {
  margin-top: 30px;
  width: 170px;
  height: 45px;
  flex-shrink: 0;
  color: black;
  background-color: white;
  border: none;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  transition: all ease-in 300ms;

}

.firstSectionChild1Button:hover {
  transform: scale(1.04);
  /* box-shadow: 5px 5px 10px white; */
  box-shadow: 3px 1px 44px -12px #ffffff94;
}

/* .firstSectionChild2 {
  width: 40%;
} */

.firstSectionContainerTwo {
  width: 57%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.firstSectionContainerTwoHead {
  color: #FFF;
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 0;
  z-index: 12;
}


.firstSectionContainerTwoPara {
  color: #D9D9D9;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
}

.secondSection {
  /* background-image: url("./assets/secondSectionBgImg.svg"); */
  height: 600px;
  background-size: cover;
  background-position: center;
  z-index: -1;
  position: relative;
}

.outsourchingButtonContainer {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  bottom: -45%;
  left: 0;
  right: 0;
  z-index: 9999;
}

.outsourchingButton1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 210px;
  padding: 0px 10px;
  min-width: 200px;
  height: 50px;
  border-radius: 4.288px;
  background: #EDD569;
  color: #171717;
  font-size: 13px;
  font-weight: 600;
  z-index: 101;

}

.outsourchingButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 210px;
  padding: 0px 10px;
  min-width: 200px;
  height: 50px;
  border-radius: 4.288px;
  background: #F4F4F4;
  color: #171717;
  font-size: 13px;
  font-weight: 600;
  z-index: 101;

}





/* Services style  */

.servicesMain {
  background-color: #212121;
  height: auto;
  padding-bottom: 40px;
  position: relative;
}

.servicesMainContainer {
  width: 80%;
  margin-left: auto;
  margin-right: auto;

}

.servicesMainContainerHead {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #d9d9d64b;
  padding-top: 30px;

}

.servicesMainContainerHeadingDiv {
  width: 50%;
  height: 110px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-right: 1px solid #d9d9d64b;
}

.servicesMainContainerHeading {
  color: #FFF;
  text-align: start;
  font-size: 35px;
  font-weight: 700;
}

.servicesMainContainerParaDiv {
  width: 40%;
  height: 110px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.servicesMainContainerPara {
  color: #FFF;
  text-align: start;
  font-size: 13px;
}

.servicesMainSection {
  display: flex;
  justify-content: space-between;
}

.servicesMainSectionChild1 {
  border-right: 1px solid #d9d9d64b;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}

.servicesMainSectionChild2 {
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-top: 10%;
}

/* services Component style  */

.servicesCompMain {
  margin-top: 50px;
  width: 70%;
}

.servicesCompHead {
  margin-top: 30px;
  color: #FFF;
  font-size: 27px;
  font-weight: 700;
  text-align: left;
}

.servicesCompPara {
  color: #FFF;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 13px;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
}

.imagetopBorder {
  border-top: 1px solid #d9d9d64b;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 50px;
}



/* Services Update Section  */

.servicesHeadContainer {

  background-image: url("./assets/ourServicesTopBgImgPng.png");
  width: 100%;
  height: 293px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

}




.servicesHeadings {
  margin-top: 70px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.servicesHeadingText {
  color: #FFF;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 0;
}

.servicesParaText {
  margin-top: 5px;
  color: #FFF;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
}


.servicesCompUpdateMain {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;
  z-index: 101;
  position: relative;
}

.servicesCompUpdateMain1 {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;
}

.servicesCompUpdateChild1 {
  width: 45%;
  z-index: 1000;
}

.serviceBgGroupImg {

  position: absolute;
  left: 0;
  bottom: -65%;
  width: 80%;
  height: 300px;
  background-image: url("./assets/groupBottomLineImg.svg");
  background-size: cover, contain;
  background-position: center;
  background-repeat: no-repeat;

}

.serviceBgGroupImg1 {
  position: absolute;
  right: 0;
  top: -45%;
  width: 80%;
  height: 300px;
  background-image: url("./assets/groupTopLineImg.svg");
  background-size: cover, contain;
  background-position: center;
  background-repeat: no-repeat;

}

.servicesCompUpdateChild2 {
  text-align: left;
  width: 45%;
}

.servicesCompUpdateChildHead {
  color: #FFF;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;
}

.servicesCompUpdateChildPara {
  margin-top: 5px;
  color: #FFF;
  font-size: 13px;
  font-weight: 400;
  line-height: 150%;
  /* 27px */
}

.betweenImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}


/* choose Us style  */

.chooseUsMain {
  background-color: #171717;
  position: relative;
  padding-bottom: 80px;
}

.chooseUsMainContainer {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 80px;
}

.chooseUsMainContainerChild1 {
  width: 45%;
  text-align: left;
  z-index: 101;
}

.chooseUsMainContainerChild1Head {
  color: #FFF;
  font-size: 40px;
  font-weight: 700;
}

.chooseUsMainContainerChild1Para {
  color: #FFF;
  font-size: 12px;

}

.chooseUsMainContainerChild1Button {
  margin-top: 30px;
  width: 170px;
  height: 45px;
  flex-shrink: 0;
  color: black;
  background-color: white;
  border: none;
  font-size: 14px;
  font-weight: 700;
  transition: all ease-in 300ms;
  cursor: pointer;
  position: relative;
  z-index: 101;
}

.chooseUsMainContainerChild1Button:hover {
  transform: scale(1.04);
  /* box-shadow: 5px 5px 10px white; */
  box-shadow: 3px 1px 44px -12px #ffffff94;
  position: relative;
  z-index: 101;
}

.chooseUsMainContainerChild2 {
  width: 45%;
  z-index: 2;
}

.industriesHead {
  /* margin-top: 12%; */
  padding-top: 100px;
  color: #FFF;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 100px;
  margin-bottom: 0;
}

.chooseUsSecondContainer {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.chooseUsSecondContainerText {
  writing-mode: vertical-rl;
  white-space: nowrap;
  transform: rotate(180deg);
  text-align: center;
  font-size: 14px;

}

.chooseUsSecondContainer1Text {
  font-weight: 700;
}

.chooseUsSecondContainer1Active {
  margin-top: 30px;
  z-index: 3;
  width: 25%;
  min-width: 160px;
  height: 300px;
  color: white;
  background-image: url("./assets/industriesImage1.svg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  transition: .5s ease-in all;
  background-color: #edd5693a;
  background-blend-mode: overlay;



}

.chooseUsSecondContainer1 {
  margin-top: 30px;
  z-index: 3;
  width: 10%;
  min-width: 60px;
  height: 300px;
  color: white;
  background-image: url("./assets/industriesImage1.svg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #171717d7;
  background-blend-mode: overlay;
}

.chooseUsSecondContainer2Active {
  margin-top: 30px;
  z-index: 3;
  width: 25%;
  min-width: 160px;
  height: 300px;
  color: white;
  background-image: url("./assets/industriesImage2.svg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  transition: .5s ease-in all;
  background-color: #edd5693a;
  background-blend-mode: overlay;

}

.chooseUsSecondContainer2 {
  margin-top: 30px;
  width: 10%;
  min-width: 60px;
  height: 300px;
  color: white;
  background-image: url("./assets/industriesImage2.svg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #171717d7;
  background-blend-mode: overlay;

}

.chooseUsSecondContainer3Active {
  margin-top: 30px;
  z-index: 3;
  width: 25%;
  min-width: 160px;
  height: 300px;
  color: white;
  background-image: url("./assets/industriesImage3.svg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  transition: .5s ease-in all;
  background-color: #edd5693a;
  background-blend-mode: overlay;

}

.chooseUsSecondContainer3 {
  margin-top: 30px;
  width: 10%;
  min-width: 60px;
  height: 300px;
  color: white;
  background-image: url("./assets/industriesImage3.svg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #171717d7;
  background-blend-mode: overlay;
}

.chooseUsSecondContainer4Active {
  margin-top: 30px;
  z-index: 3;
  width: 25%;
  min-width: 160px;
  height: 300px;
  color: white;
  background-image: url("./assets/industriesImage4.svg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  transition: .5s ease-in all;
  background-color: #edd5693a;
  background-blend-mode: overlay;
}

.chooseUsSecondContainer4 {
  margin-top: 30px;
  width: 10%;
  min-width: 60px;
  height: 300px;
  color: white;
  background-image: url("./assets/industriesImage4.svg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #171717d7;
  background-blend-mode: overlay;
}

.chooseUsSecondContainer5Active {
  margin-top: 30px;
  z-index: 3;
  width: 25%;
  min-width: 160px;
  height: 300px;
  color: white;
  background-image: url("./assets/industriesImage5.svg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  transition: .5s ease-in all;
  background-color: #edd5693a;
  background-blend-mode: overlay;
}

.chooseUsSecondContainer5 {
  margin-top: 30px;
  width: 10%;
  min-width: 60px;
  height: 300px;
  color: white;
  background-image: url("./assets/industriesImage5.svg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #171717d7;
  background-blend-mode: overlay;
}

.chooseUsSecondContainer6Active {
  margin-top: 30px;
  z-index: 3;
  width: 25%;
  min-width: 160px;
  height: 300px;
  color: white;
  background-image: url("./assets/industriesImage6.svg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  transition: .5s ease-in all;
  background-color: #edd5693a;
  background-blend-mode: overlay;
}

.chooseUsSecondContainer6 {
  margin-top: 30px;
  width: 10%;
  min-width: 60px;
  height: 300px;
  color: white;
  background-image: url("./assets/industriesImage6.svg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #171717d7;
  background-blend-mode: overlay;
}

.chooseUsSecondContainer7Active {
  margin-top: 30px;
  z-index: 3;
  width: 25%;
  min-width: 160px;
  height: 300px;
  color: white;
  background-image: url("./assets/industriesImage7.svg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  transition: .5s ease-in all;
  background-color: #edd5693a;
  background-blend-mode: overlay;

}

.chooseUsSecondContainer7 {
  margin-top: 30px;
  width: 10%;
  min-width: 60px;
  height: 300px;
  color: white;
  background-image: url("./assets/industriesImage7.svg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #171717d7;
  background-blend-mode: overlay;
}


.choseUsBgImgContainer {
  position: absolute;
  /* top: 40%; */
  left: 0;
  width: 15%;
}


/* Impact Section Style  */


.impactMain {
  width: 100%;
  /* height: 500px; */
  background-image: url("./assets//impactBgImg.svg");
  background-size: cover;
  background-position: center;
  /* background-color: #edd569e7; */
  /* background: linear-gradient(120deg, rgba(237, 213, 105, 0.80) 0%, rgba(151, 118, 25, 0.80) 100%); */
  background-color: rgb(171, 133, 27);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 60px; */
}

.impactMainHead {
  text-align: center;
  color: #FFF;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 30px;
}

.impactMainPara {
  text-align: center;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 65px;
}

.impactMainContainer {
  width: 80%;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(300deg, #191919 0%, #343434 90%);
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 30px;

}

.impactMainChild {
  width: 13%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.impactMainContainerHead {
  color: #FFFFF7;
  text-align: center;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 70px;
  margin: 0;
}

.impactMainContainerPara {
  color: #E7E7E7;
  margin: 0;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}


/* Footer style  */


.footerMain {
  background-color: #171717;
}

.footerMainContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 40px;
  padding-top: 40px;
  padding-left: 0;
  padding-right: 0;
}



.footerMainContainer2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
}

.footerMainContainer1Child {
  width: 40%;
}

.footerMainContainer2Child {
  width: 100%;
  min-width: 200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  gap: 20px;
}


.footerMainContainer2Child1 {
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footerMainContainer2Child2Number {
  min-width: 100px;
  color: #FFF;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footerMainContainer2Child3Text {
  min-width: 60px;
  color: #ffffff8c;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 20px;
}

.footerMainBottom {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid #d9d9d93d;
  padding-top: 30px;
  padding-bottom: 30px;
  gap: 20px;
}

.footerMainContainer2Child2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footerMainContainer2Child3 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.footerMainContainer2Child3 div {
  min-width: auto;
}

.footerMainBottomChild1 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}

.footerMainBottomChild1 div {
  min-width: 70px;
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.footerMainBottomChild1 div:hover {
  transform: translateY(-3px);
}


.footerIcons1 {
  color: #EDD569;
  font-size: 20px;
  cursor: pointer;
}

.footerIcons2 {
  color: #EDD569;
  font-size: 20px;
  cursor: pointer;
}

.footerIcons3 {
  color: #EDD569;
  font-size: 20px;
  cursor: pointer;
}

.footerIcons4 {
  color: #EDD569;
  font-size: 20px;
  cursor: pointer;
}

.footerIcons5 {
  color: #EDD569;
  font-size: 20px;
  cursor: pointer;
}

.footerIcons6 {
  color: #EDD569;
  font-size: 20px;
  cursor: pointer;
}

.footerIcons7 {
  color: #EDD569;
  font-size: 20px;
  cursor: pointer;
}

.footerIcons8 {
  color: #EDD569;
  font-size: 20px;
  cursor: pointer;
}

.footerMainBottomChild2 {
  min-width: 190px;
  color: #ffffff83;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.headerMainContainer1Inner {
  width: 75%;
}

.impactMainInner {
  width: 90%;
}

/* .zoom-effect {
  cursor: pointer;
  transition: transform 0.5s ease;
}

.zoom-effect:hover {
  transform: scale(1.1);
} */

@keyframes countUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.count-up {
  display: inline-block;
  animation: countUp 1.5s ease-out forwards;
  opacity: 0;
  transform: translateY(20px);
}

.paraInnerSpan {
  font-size: 19px;
}



@media (max-width: 321px) {

  .serviceBgGroupImg {
    background-position: unset;
  }

  .serviceBgGroupImg1 {
    background-position: unset;
  }


  .headerMainChild1Head {
    font-size: 33px;
  }

  .headerMainChild2Head {
    font-size: 33px;
  }

  .jobSeekerImg {
    position: absolute;
    top: -9%;
    left: -15%;
  }

  .companiesImg {
    top: -4%;
    right: -6%;
  }

  .firstSectionChild1Head {
    font-size: 33px;
  }

  .firstSectionContainerTwoHead {
    font-size: 22px;
  }

  .firstSectionContainerTwoPara {
    font-size: 12px;
  }

  .servicesMainContainerHeading {
    font-size: 30px;
  }

  .chooseUsMainContainerChild1Head {
    font-size: 35px;
  }

  .industriesHead {
    font-size: 30px;
  }

  .impactMainHead {
    font-size: 33px;
  }

  .impactMainContainerHead {
    font-size: 43px;
  }

  .NavbarButtonContainer {
    width: 90px;
    height: 35px;
    font-size: 12px;
  }

  .chooseUsSecondContainer1 {
    min-width: 230px;
  }

  .navbarLinksContainer {
    display: none;
  }

  .navbarButtonContainer {
    display: none;
  }

  .headerMain {
    /* padding-top: 90px; */
    flex-direction: column;
    height: auto;
  }

  .headerMainContainer1 {
    width: 100%;
  }

  .headerMainContainer1Inner {
    width: 80%;
  }

  .headerMainContainer2 {
    width: 100%;
  }



  .firstSectionContainerTwo {
    width: 80%;
  }

  .firstSectionContainer {
    flex-direction: column;
    padding-top: 30px;
    gap: 65px;
  }

  .firstSectionChild1 {
    width: 80%;
  }

  .firstSectionChild2 {

    width: 100%;
  }

  .firstSection {
    padding-bottom: 90px;
  }

  .secondSection {
    height: 150px;
  }

  .chooseUsMainContainer {
    flex-direction: column;
    gap: 50px;
  }

  .chooseUsMainContainerChild1 {
    width: 100%;
  }

  .chooseUsMainContainerChild2 {
    width: 100%;
  }

  .impactMainContainer {
    /* flex-direction: column;
    width: 50%; */
    gap: 30px;
    flex-wrap: wrap;
    width: 70%;
  }

  .impactMainChild {
    width: 45%;
  }

  .servicesMainContainerHead {
    flex-direction: column;
    align-items: center;

  }

  .servicesMainContainerHeadingDiv {
    width: 100%;
    justify-content: center;
    border-right: none;
    height: auto;
  }

  .servicesMainContainerHeading {
    margin-top: 0;
  }

  .servicesMainContainerParaDiv {
    width: 100%;
    height: auto;
  }

  .servicesMainContainerPara {
    margin-top: 0;
    margin-bottom: 30px;
  }

  .servicesMainSection {
    flex-direction: column;
  }

  .servicesMainSectionChild1 {
    width: 100%;
    border-right: none;
    align-items: center;
    padding-bottom: 60px;
    border-bottom: 1px solid #d9d9d64b;
  }

  .servicesMainSectionChild2 {
    width: 100%;
  }

  .servicesCompMain {
    width: 100%;
  }

  .footerMainContainer {
    flex-direction: column;
    gap: 30px;
    /* align-items: flex-start; */
  }

  .footerMainContainer2 {
    /* flex-direction: row; */
    width: 100%;
  }

  .footerMainContainer2Child {
    justify-content: flex-start;
    gap: 25px;
    min-width: 180px;
  }

  /* .footerMainContainer2Child2 {
    flex-direction: column;
    gap: 15px;
  } */

  .footerMainContainer2Child3 div {
    margin-bottom: 5px;
  }

  .footerMainBottomChild1 {}

  .footerMainBottom {
    justify-content: center;
  }

  .footerMainBottomChild1 div {
    text-align: left;
  }

  .servicesCompUpdateMain {
    flex-direction: column;
  }

  .servicesCompUpdateChild1 {
    /* text-align: left; */
    width: 80%;
  }

  .servicesCompUpdateChild2 {
    /* text-align: left; */
    margin-top: 40px;
    width: 80%;
  }

  .servicesCompUpdateMain1 {
    flex-direction: column;
  }

  .outsourchingButton {
    width: 100px;
    min-width: 100px;
    font-size: 10px;
  }

  .outsourchingButton1 {
    width: 100px;
    min-width: 100px;
    font-size: 10px;
  }

  .outsourchingButtonContainer {
    bottom: -44%;
  }

  .servicesHeadingText {
    font-size: 35px;
  }
}

@media (min-width: 321px) and (max-width: 480px) {


  .serviceBgGroupImg {
    background-position: unset;
  }

  .serviceBgGroupImg1 {
    background-position: unset;
  }


  .firstSectionContainerTwoHead {
    font-size: 26px;
  }

  .firstSectionContainerTwoPara {
    font-size: 14px;
  }

  .outsourchingButton {
    width: 100px;
    min-width: 100px;
    font-size: 10px;
  }

  .outsourchingButton1 {
    width: 100px;
    min-width: 100px;
    font-size: 10px;
  }

  .outsourchingButtonContainer {
    bottom: -43%;
  }

  .servicesCompUpdateMain {
    flex-direction: column;
  }

  .servicesCompUpdateChild1 {
    /* text-align: left; */
    width: 80%;
  }

  .servicesCompUpdateChild2 {
    /* text-align: left; */
    margin-top: 40px;
    width: 80%;
  }

  .servicesCompUpdateMain1 {
    flex-direction: column;
  }

  .headerMainChild1Head {
    font-size: 33px;
  }

  .headerMainChild2Head {
    font-size: 33px;
  }

  .jobSeekerImg {
    position: absolute;
    top: -10%;
    left: -12%;
  }

  .companiesImg {
    top: -4%;
    right: -7%;
  }

  .firstSectionChild1Head {
    font-size: 33px;
  }

  .servicesMainContainerHeading {
    font-size: 30px;
  }

  .chooseUsMainContainerChild1Head {
    font-size: 35px;
  }

  .industriesHead {
    font-size: 30px;
  }

  .impactMainHead {
    font-size: 33px;
  }

  .impactMainContainerHead {
    font-size: 43px;
  }


  .NavbarButtonContainer {
    width: 90px;
    height: 35px;
    font-size: 12px;
  }

  .navbarLinksContainer {
    display: none;
  }

  .navbarButtonContainer {
    display: none;
  }

  .headerMain {
    flex-direction: column;
    height: auto;
  }

  .headerMainContainer1 {
    width: 100%;
  }

  .headerMainContainer1Inner {
    width: 80%;
  }

  .headerMainContainer2 {
    width: 100%;
  }



  .firstSectionContainerTwo {
    width: 80%;
  }

  .firstSectionContainer {
    flex-direction: column;
    padding-top: 30px;
    gap: 65px;
  }

  .firstSectionChild1 {
    width: 80%;
  }

  .firstSectionChild2 {

    width: 100%;
  }

  .firstSection {
    padding-bottom: 95px;
  }

  .secondSection {
    height: 170px;
  }

  .chooseUsMainContainer {
    flex-direction: column;
    gap: 50px;
  }

  .chooseUsMainContainerChild1 {
    width: 100%;
  }

  .chooseUsMainContainerChild2 {
    width: 100%;
  }

  .impactMainContainer {
    gap: 30px;
    flex-wrap: wrap;
    width: 70%;
  }

  .impactMainChild {
    width: 45%;
  }

  .servicesMainContainerHead {
    flex-direction: column;
    align-items: center;

  }

  .servicesMainContainerHeadingDiv {
    width: 100%;
    justify-content: center;
    border-right: none;
    height: auto;
  }

  .servicesMainContainerHeading {
    margin-top: 0;
  }

  .servicesMainContainerParaDiv {
    width: 100%;
    height: auto;
  }

  .servicesMainContainerPara {
    margin-top: 0;
    margin-bottom: 30px;
  }

  .servicesMainSection {
    flex-direction: column;
  }

  .servicesMainSectionChild1 {
    width: 100%;
    border-right: none;
    align-items: center;
    padding-bottom: 60px;
    border-bottom: 1px solid #d9d9d64b;
  }

  .servicesMainSectionChild2 {
    width: 100%;
  }

  .servicesCompMain {
    width: 100%;
  }

  .footerMainContainer {
    flex-direction: column;
    gap: 30px;
  }

  .footerMainContainer2 {
    width: 100%;
  }

  .footerMainContainer2Child {
    justify-content: flex-start;
    gap: 25px;
  }

  /* .footerMainContainer2Child2 {
    flex-direction: column;
    gap: 15px;
  } */

  .footerMainContainer2Child3 div {
    margin-bottom: 5px;
  }

  .footerMainBottomChild1 {}

  .footerMainBottom {
    justify-content: center;
  }

  .footerMainBottomChild1 div {
    text-align: left;
  }

  .servicesHeadingText {
    font-size: 35px;
  }
}

@media (min-width: 481px) and (max-width: 576px) {



  .serviceBgGroupImg {
    background-position: unset;
    bottom: -50%;
  }

  .serviceBgGroupImg1 {
    background-position: unset;
    top: -30%;
  }

  .outsourchingButton {
    width: 150px;
    min-width: 150px;
  }

  .outsourchingButton1 {
    width: 150px;
    min-width: 150px;
  }

  .outsourchingButtonContainer {
    bottom: -33%;
  }

  .servicesCompUpdateMain {
    flex-direction: column;
  }

  .servicesCompUpdateChild1 {
    /* text-align: left; */
    width: 80%;
  }

  .servicesCompUpdateChild2 {
    margin-top: 40px;
    /* text-align: left; */
    width: 80%;
  }

  .servicesCompUpdateMain1 {
    flex-direction: column;
  }

  .navbarLinksContainer {
    display: none;
  }

  .navbarButtonContainer {
    display: none;
  }

  .headerMain {
    /* padding-top: 90px; */
    flex-direction: column;
    height: auto;
  }

  .headerMainContainer1 {
    width: 100%;
  }

  .headerMainContainer1Inner {
    width: 80%;
  }

  .headerMainContainer2 {
    width: 100%;
  }

  .companiesImg {
    top: -2%;
    right: -10%;
  }

  .firstSectionContainerTwo {
    width: 80%;
  }

  .firstSectionContainer {
    flex-direction: column;
    padding-top: 30px;
    gap: 65px;
  }

  .firstSectionChild1 {
    width: 80%;
  }

  .firstSectionChild2 {

    width: 100%;
  }

  .firstSection {
    padding-bottom: 90px;
  }

  .secondSection {
    height: 250px;
  }

  .chooseUsMainContainer {
    flex-direction: column;
    gap: 50px;
  }

  .chooseUsMainContainerChild1 {
    width: 100%;
  }

  .chooseUsMainContainerChild2 {
    width: 100%;
  }

  .impactMainContainer {
    /* flex-direction: column;
    width: 50%; */
    gap: 30px;
    flex-wrap: wrap;
  }

  .impactMainChild {
    width: 45%;
  }

  .servicesMainContainerHead {
    flex-direction: column;
    align-items: center;

  }

  .servicesMainContainerHeadingDiv {
    width: 100%;
    justify-content: center;
    border-right: none;
    height: auto;
  }

  .servicesMainContainerHeading {
    margin-top: 0;
  }

  .servicesMainContainerParaDiv {
    width: 100%;
    height: auto;
  }

  .servicesMainContainerPara {
    margin-top: 0;
    margin-bottom: 30px;
  }

  .servicesMainSection {
    flex-direction: column;
  }

  .servicesMainSectionChild1 {
    width: 100%;
    border-right: none;
    align-items: center;
    padding-bottom: 60px;
    border-bottom: 1px solid #d9d9d64b;
  }

  .servicesMainSectionChild2 {
    width: 100%;
  }

  .servicesCompMain {
    width: 100%;
  }

  .footerMainContainer {
    flex-direction: column;
    gap: 30px;
    /* align-items: flex-start; */
  }

  .footerMainContainer2 {
    flex-direction: row;
    width: 100%;
  }

  .footerMainContainer2Child {
    justify-content: flex-start;
    gap: 25px;
  }

  /* .footerMainContainer2Child2 {
    flex-direction: column;
    gap: 15px;
  } */

  .footerMainContainer2Child3 div {
    margin-bottom: 5px;
  }

  .footerMainBottomChild1 {}

  .footerMainBottom {
    justify-content: center;
  }

  .firstSectionContainerTwoPara {
    width: 75%;
  }

  .jobSeekerImg {
    position: absolute;
    top: -10%;
    left: -8%;
  }

}

@media (min-width: 576px) and (max-width: 768px) {

  /* .footerMainContainer2Child2 {
    justify-content: center;
  } */

  .serviceBgGroupImg {
    background-position: unset;
    bottom: -75%;
  }

  .serviceBgGroupImg1 {
    background-position: unset;
    top: -75%;
  }

  .outsourchingButton {
    width: 150px;
    min-width: 150px;
  }

  .outsourchingButton1 {
    width: 150px;
    min-width: 150px;
  }

  .headerMainChild2Head {
    font-size: 35px;
  }

  .headerMainChild1Head {
    font-size: 35px;
  }

  .firstSectionChild1Head {
    font-size: 35px;
  }

  .industriesHead {
    font-size: 36px;
  }


  .navbarLinksContainer {
    display: none;
  }

  .navbarButtonContainer {
    display: none;
  }

  .jobSeekerImg {
    position: absolute;
    top: -7%;
    left: -15%;
  }

  .companiesImg {
    top: -4%;
    right: -9%;
  }


  .firstSection {
    padding-bottom: 90px;
  }

  .secondSection {
    height: 295px;
  }

  .chooseUsMainContainer {
    flex-direction: column;
    gap: 50px;
  }

  .chooseUsMainContainerChild1 {
    width: 100%;
  }

  .chooseUsMainContainerChild2 {
    width: 100%;
  }

  .impactMainContainer {
    flex-wrap: wrap;
    gap: 30px;
  }

  .impactMainChild {
    width: 45%;
  }

  .footerMainBottom {
    justify-content: center;
  }

  .outsourchingButtonContainer {
    bottom: -33%;
  }

  .outsourchingButton {}
}

@media (min-width: 768px) and (max-width: 992px) {

  .firstSectionContainerTwoHead {
    font-size: 31px;
  }

  .navbarLinksContainer {
    display: none;
  }

  .navbarButtonContainer {
    display: none;
  }

  .companiesImg {
    top: 0%;
    right: 0%;
  }

  .jobSeekerImg {
    /* top: -12%; */

  }

  .firstSection {
    padding-bottom: 50px;
  }

  .secondSection {
    height: 400px;
  }

  .footerMainBottom {
    justify-content: center;
  }

  .outsourchingButtonContainer {
    bottom: -41%;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .hide-ico {
    display: none;
  }

  .companiesImg {
    top: -3%;
    right: 14%;
  }

  .jobSeekerImg {
    top: -7%;

  }

  .firstSection {
    padding-bottom: 50px;
  }

  .secondSection {
    height: 500px;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .outsourchingButtonContainer {
    bottom: -55%;
  }

  .hide-ico {
    display: none;
  }

  .companiesImg {
    top: -1%;
    right: -10%;
  }

  .jobSeekerImg {
    top: -10%;

  }

  .secondSection {
    height: 520px;
  }

}

@media (min-width: 1200px) and (max-width: 1384px) {
  .hide-ico {
    display: none;
  }

  .footerMainContainer2Child1 {
    font-size: 14px;
  }
}

@media (min-width: 1384px) and (max-width: 1440px) {
  .hide-ico {
    display: none;
  }

  .footerMainContainer2Child1 {
    font-size: 15px;
  }

  .companiesImg {
    position: absolute;
    top: -2%;
    right: 10%
  }
}

@media (min-width: 1440px) and (max-width: 1650px) {
  .hide-ico {
    display: none;
  }

  .footerMainContainer2Child1 {
    font-size: 15px;
  }

}

@media (min-width: 1650px) {
  .companiesImg {
    position: absolute;
    top: -4%;
    right: -7%
  }

  .headerMainChild1Head {
    font-size: 44px;
  }

  .headerMainChild1Para {
    font-size: 16px;
  }

  .headerMainChild2Head {
    font-size: 44px;
  }

  .headerMainChild2Para {
    font-size: 16px;
  }

  .firstSectionChild1Head {
    font-size: 44px;
  }

  .firstSectionChild1Para {
    font-size: 16px;
  }

  .firstSectionContainerTwoHead {
    font-size: 40px;
  }

  .firstSectionContainerTwoPara {
    font-size: 18px;
  }

  .servicesMainContainerHeading {
    font-size: 40px;
  }

  .servicesMainContainerPara {
    font-size: 16px;
  }

  .servicesCompHead {
    font-size: 32px;
  }

  .servicesCompPara {
    font-size: 16px;
  }

  .chooseUsMainContainerChild1Head {
    font-size: 45px;
  }

  .chooseUsMainContainerChild1Para {
    font-size: 15px;
  }

  .paraInnerSpan {
    font-size: 25px;
  }

  .industriesHead {
    font-size: 45px;
  }

  .chooseUsSecondContainer1Text {
    font-size: 22px;
  }

  .chooseUsSecondContainerText {
    font-size: 18px;
  }

  .chooseUsSecondContainer1Active {
    height: 500px;
  }

  .chooseUsSecondContainer2Active {
    height: 500px;
  }

  .chooseUsSecondContainer3Active {
    height: 500px;
  }

  .chooseUsSecondContainer4Active {
    height: 500px;
  }

  .chooseUsSecondContainer5Active {
    height: 500px;
  }

  .chooseUsSecondContainer6Active {
    height: 500px;
  }

  .chooseUsSecondContainer7Active {
    height: 500px;
  }

  .chooseUsSecondContainer1 {
    height: 500px;
  }

  .chooseUsSecondContainer2 {
    height: 500px;
  }

  .chooseUsSecondContainer3 {
    height: 500px;
  }

  .chooseUsSecondContainer4 {
    height: 500px;
  }

  .chooseUsSecondContainer5 {
    height: 500px;
  }

  .chooseUsSecondContainer6 {
    height: 500px;
  }

  .chooseUsSecondContainer7 {
    height: 500px;
  }

  .impactMainHead {
    font-size: 45px;
  }

  .impactMainPara {
    font-size: 18px;
  }

  .impactMainContainerPara {
    font-size: 16px;
  }

  .footerMainContainer2Child1 {
    font-size: 16px;
  }

  .footerMainContainer2Child2Number {
    font-size: 16px;
  }

  .footerMainContainer2Child3 div {
    font-size: 18px;
  }

  .footerIcons1 {
    font-size: 25px;
  }

  .footerIcons2 {
    font-size: 25px;
  }

  .footerIcons3 {
    font-size: 25px;
  }

  .footerIcons4 {
    font-size: 25px;
  }

  .footerIcons5 {
    font-size: 25px;
  }

  .footerIcons6 {
    font-size: 25px;
  }

  .footerIcons7 {
    font-size: 25px;
  }

  .footerIcons8 {
    font-size: 25px;
  }

  .footerMainBottomChild1 div {
    font-size: 16px;
  }

  .footerMainBottomChild2 {
    font-size: 12px;
  }

  .hide-ico {
    display: none;
  }
}
.featureGradientYellow {
  background: linear-gradient(150deg, #EDD569 0%, #977619 100%);
}
.featureGradientBlack {
  background: linear-gradient(300deg, #191919 0%, #343434 90%);
}
/* Custom style for the dropdown */
.flag-dropdown {
  background-color: #333333 !important; /* Background color */
  color: white !important; /* Text color */
  border: 1px solid #525252 !important; /* Border */
}

/* Custom style for the dropdown country list */
.country-list {
  background-color: #333333 !important;
  color: white !important;
}

/* Custom style for the country options in the dropdown */
.country-list .country {
  background-color: #333333 !important;
  color: white !important;
}

/* Option hover style */
.country-list .country:hover {
  background-color: #525252 !important;
}
/* Add this to your global CSS file */
.truncate-lines-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


